import React, { Component } from 'react';
import VenueBanner from './VenueBanner';

class VenueListItem extends Component {
  render() {
    return (
      <div class="row">
        <div class="column">
          <VenueBanner name={this.props.name} banner={this.props.banner} />
        </div>
        <div class="column" style={{ paddingLeft: 16 }}>
          <h3>{this.props.name}</h3>
          <p class="card-text">Description goes here</p>
          <p>
            <small class="text-muted">
              {this.props.location} - {this.props.country}
            </small>
          </p>
        </div>
      </div>
    );
  }
}

export default VenueListItem;
