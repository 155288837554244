import React, { Component } from 'react';
import './App.css';
import VenueList from './venue/VenueList';
import VenueDetail from './venue/VenueDetail';
import TrackDetail from './track/TrackDetail';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div class="container">
          <header class="blog-header py-3" style={{ background: 'white' }}>
            <div class="row flex-nowrap justify-content-between align-items-center">
              <div class="col-4 text-center">
                <a class="blog-header-logo text-dark" href="/">
                  TheKartingTour<small>.com</small>
                </a>
              </div>
              <div class="col-4 d-flex justify-content-end align-items-center">
                <nav>
                  <Link to="/venues">Locations</Link>
                  <Link to="/venues">Latest results</Link>
                  <Link to="/venues">Contact</Link>
                </nav>
              </div>
            </div>
          </header>

          <div style={{ marginTop: 20, background: 'white' }}>
            <Switch>
              <Route exact path="/" component={VenueList} />
              <Route
                path="/venues/:id/tracks/:trackId"
                component={TrackDetail}
              />
              <Route path="/venues/:id" component={VenueDetail} />
              <Route path="/venues" component={VenueList} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
