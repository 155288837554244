import React, { Component } from 'react';
import Laptime from './Laptime';

export default class TimingBoard extends Component {
  render() {
    // Init
    let withSectorTimes = this.props.withSectorTimes;

    // Find the fastest lap
    let fastestLap = null;

    this.props.times.forEach(function(value) {
      if (fastestLap === null || value.lap < fastestLap.lap) {
        fastestLap = value;
      }
    });

    // Build all entries
    let elements = this.props.times.map(function(lap, index) {
      return (
        <Laptime
          nr={lap.nr}
          time={lap.lap}
          index={index}
          fastestLap={lap === fastestLap}
          withSectorTimes={withSectorTimes}
          s1={lap.s1}
          s2={lap.s2}
          s3={lap.s3}
        />
      );
    });

    if (withSectorTimes) {
      return (
        <div>
          <Laptime
            nr="#"
            time="Time"
            index="-1"
            s1="S1"
            s2="S2"
            s3="S3"
            withSectorTimes="true"
          />
          {elements}
        </div>
      );
    }

    // Render it
    return (
      <div>
        <Laptime nr="#" time="Time" index="-1" />
        {elements}
      </div>
    );
  }
}
