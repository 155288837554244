import firebase from 'firebase';

const hostname = window && window.location && window.location.hostname;

var config;

if (hostname === 'dev.thekartingtour.com' || hostname === 'localhost') {
  config = {
    apiKey: 'AIzaSyC-Etkkyrj015oTMUuUxcBX4FrKJS71Oz0',
    authDomain: 'home-0005-karting-timings-dev.firebaseapp.com',
    databaseURL: 'https://home-0005-karting-timings-dev.firebaseio.com',
    projectId: 'home-0005-karting-timings-dev',
    storageBucket: 'home-0005-karting-timings-dev.appspot.com',
    messagingSenderId: '727891555056',
  };
} else {
  config = {
    apiKey: 'AIzaSyB6HLl6DYanwrZ4FdSGbVDMqbik7ktylZA',
    authDomain: 'home-0005-karting-timings.firebaseapp.com',
    databaseURL: 'https://home-0005-karting-timings.firebaseio.com',
    projectId: 'home-0005-karting-timings',
    storageBucket: 'home-0005-karting-timings.appspot.com',
    messagingSenderId: '589148306810',
  };
}

firebase.initializeApp(config);
export default firebase;
