import React from 'react';
import firebase from '../firebase';
import TrackListItem from '../track/TrackListItem';
import VenueBanner from './VenueBanner';
import { Link } from 'react-router-dom';

class VenueDetail extends React.Component {
  constructor(match) {
    super(match);

    let self = this;

    this.state = {
      venue: null,
      tracks: null,
    };

    this.id = match.match.params.id;

    let venue = firebase
      .database()
      .ref('venues/' + match.match.params.id)
      .orderByChild('priority');

    venue.once('value').then(function(snapshot) {
      let venue = snapshot.val();
      venue.key = match.match.params.id;

      let allTracks = [];

      if (venue.tracks != null) {
        Object.keys(venue.tracks).forEach(function(key) {
          let track = venue.tracks[key];
          allTracks.push({
            key: key,
            id: key,
            name: track.name,
            description: track.description,
          });
        });
      }

      self.setState({
        venue: venue,
        tracks: allTracks,
      });
    });
  }

  render() {
    if (this.state != null && this.state.venue != null) {
      let tracks;
      let venue = this.state.venue;

      if (this.state.tracks != null) {
        tracks = this.state.tracks.map(function(track, index) {
          return (
            <Link
              to={{
                pathname: '/venues/' + venue.key + '/tracks/' + track.key,
              }}
            >
              <TrackListItem {...track} />
            </Link>
          );
        });
      } else {
        tracks = null;
      }

      return (
        <div class="container">
          <div class="row">
            <div class="column">
              <VenueBanner {...this.state.venue} />
            </div>
            <div class="column" style={{ paddingLeft: 16 }}>
              <h3>{this.state.venue.name}</h3>
              <p class="card-text">Description goes here</p>
              <p>
                <small class="text-muted">{this.state.venue.location}</small>
              </p>
            </div>
          </div>

          <h3>Tracks</h3>
          {tracks}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default VenueDetail;
