import React, { Component } from 'react';
import { LapTime } from './Laptime.style';

export default class Time extends Component {
  render() {
    let defaultColor = '#FFFFFF';
    let fastestLapColor = '#F928FC';

    let laptimeColor =
      this.props.fastestLap || false ? fastestLapColor : defaultColor;

    let format = function(time) {
      if (isNaN(time)) {
        return time;
      } else {
        return ((time * 1000) / 1000).toFixed(3);
      }
    };

    return <LapTime color={laptimeColor}>{format(this.props.time)}</LapTime>;
  }
}
