import React from 'react';
import firebase from '../firebase';
import { Heat } from '../components';

export default class TrackDetail extends React.Component {
  constructor(match) {
    super(match);

    let self = this;

    this.state = {
      venue: null,
      tracks: null,
    };

    let trackId = match.match.params.trackId;

    let sessions = firebase
      .database()
      .ref('sessions')
      .orderByChild('timestamp');

    sessions.once('value').then(function(snapshot) {
      console.log(snapshot.val());
      let data = Object.values(snapshot.val()).filter(
        session => session.track === trackId,
      );

      let items = [];

      data.forEach(element => {
        items.push(element);
      });

      self.setState({
        data: items.sort(function(a, b) {
          return b.timestamp.localeCompare(a.timestamp);
        }),
      });
    });

    // let track = firebase
    //   .database()
    //   .ref(
    //     'venues/' +
    //       match.match.params.id +
    //       '/tracks/' +
    //       match.match.params.trackId,
    //   )
    //   .orderByChild('priority');

    // track.once('value').then(function(snapshot) {
    //   let track = snapshot.val();

    //   // if (venue.tracks != null) {
    //   //   Object.keys(venue.tracks).forEach(function(key) {
    //   //     let track = venue.tracks[key];
    //   //     allTracks.push({
    //   //       key: key,
    //   //       id: key,
    //   //       name: track.name,
    //   //       description: track.description,
    //   //     });
    //   //   });
    //   // }

    //   self.setState({
    //     track: track,
    //   });
    // });
  }

  render() {
    if (this.state != null && this.state.data != null) {
      let sessions = this.state.data.map(function(session) {
        return <Heat {...session} />;
      });

      // return <div>Hello World</div>;
      return <div class="container">{sessions}</div>;

      //   if (this.state.tracks != null) {
      //     tracks = this.state.tracks.map(function(track, index) {
      //       return <TrackListItem {...track} />;
      //     });
      //   } else {
      //     tracks = null;
      //   }

      //   return (
      //     <div class="container">
      //       <div class="row">
      //         <div class="column">
      //           <VenueBanner {...this.state.venue} />
      //         </div>
      //         <div class="column" style={{ paddingLeft: 16 }}>
      //           <h3>{this.state.venue.name}</h3>
      //           <p class="card-text">Description goes here</p>
      //           <p>
      //             <small class="text-muted">{this.state.venue.location}</small>
      //           </p>
      //         </div>
      //       </div>

      //       {tracks}
      //     </div>
      //   );
    } else {
      return <div>Loading...</div>;
    }
  }
}
