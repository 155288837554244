import React, { Component } from 'react';

import TimingBoard from './timingboard';
import TimingGraph from './timinggraph';

import { HeatContainer } from './Heat.style';

export default class Heat extends Component {
  render() {
    let withSectorTimes = false;

    let times = this.props.laptimes
      .map(function(value, index) {
        if (value != null) {
          if (typeof value === 'object') {
            withSectorTimes = true;

            return {
              nr: index,
              lap: value.lap,
              s1: value.s1,
              s2: value.s2,
              s3: value.s3,
            };
          } else {
            return { nr: index, lap: value, s1: 0, s2: 0, s3: 0 };
          }
        } else {
          return null;
        }
      })
      .filter(x => x);

    let fastestLap = null;

    times.forEach(function(value) {
      if (fastestLap === null || value.lap < fastestLap.lap) {
        fastestLap = value;
      }
    });

    return (
      <div>
        <p>
          <b>Date:</b> {this.props.timestamp}
          <br />
          <b>Driver:</b> {this.props.driver}
        </p>
        <h3>Laptimes</h3>
        <HeatContainer>
          <div
            style={{
              width: 400,
              float: 'left',
              marginRight: 16,
              marginBottom: 16,
            }}
          >
            <TimingBoard times={times} withSectorTimes={withSectorTimes} />
          </div>
          <div style={{ width: 600, float: 'left' }}>
            <TimingGraph times={times} />
          </div>
        </HeatContainer>
        <hr />
      </div>
    );
  }
}
