import styled from 'styled-components';

export const HeatContainer = styled.div`
  ::after {
    content: '';
    clear: both;
    display: table;
  }
`;

export const TimingChart = styled.div`
  float: left;
  margin-left: 16px;
`;
