import React, { Component } from 'react';
import { EntryRow, LapNumber } from './Laptime.style';
import Time from './Time';

export default class Laptime extends Component {
  render() {
    let bgColor = this.props.index % 2 === 0 ? '#202020' : '#000000';
    let times = [];

    times.push(
      <Time fastestLap={this.props.fastestLap} time={this.props.time} />,
    );

    if (this.props.withSectorTimes) {
      let s1 = this.props.s1 || '-';
      let s2 = this.props.s2 || '-';
      let s3 = this.props.s3 || '-';

      times.push(<Time time={s1} />);
      times.push(<Time time={s2} />);
      times.push(<Time time={s3} />);
    }

    return (
      <EntryRow color={bgColor}>
        <LapNumber>{this.props.nr}</LapNumber>
        {times}
      </EntryRow>
    );
  }
}
