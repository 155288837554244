import styled from 'styled-components';

export const EntryRow = styled.div`
  padding: 0.5em 0;
  background: ${props => props.color || 'black'};

  ::after {
    content: '';
    clear: both;
    display: table;
  }
`;

export const LapNumber = styled.div`
  width: 60px;
  color: #2ef9f7;
  font-weight: bold;
  float: left;
  text-align: center;
  margin-right: 8px;
`;

export const LapTime = styled.div`
  width: 80px;
  color: ${props => props.color};
  font-weight: bold;
  float: left;
`;
