import React, { Component } from 'react';

import firebase from '../firebase';
class TrackListItem extends Component {
  constructor(match) {
    super(match);

    let track = this.props;

    this.state = {
      timings: null,
    };

    console.log(this.props);

    let timings = firebase
      .database()
      .ref('sessions')
      .orderByChild('track')
      .equalTo(track.id);

    console.log('all sessions:');
    timings.once('value').then(function(snapshot) {
      // let session = snapshot.val();
      // let allTracks = [];
      // if (venue.tracks != null) {
      //   Object.keys(venue.tracks).forEach(function(key) {
      //     let track = venue.tracks[key];
      //     allTracks.push({
      //       key: key,
      //       name: track.name,
      //       description: track.description,
      //     });
      //   });
      // }
      // self.setState({
      //   venue: venue,
      //   tracks: allTracks,
      // });
    });
  }

  render() {
    return (
      <div class="row">
        <div class="column">
          <h4>{this.props.name}</h4>
          <p class="card-text">{this.props.description}</p>
        </div>
      </div>
    );
  }
}

export default TrackListItem;
