import React, { Component } from 'react';
import { defaults, Line } from 'react-chartjs-2';
import { TimingGraphStyle } from './TimingGraph.style';

defaults.global.defaultFontFamily = 'Open Sans, sans-serif';

export default class TimingGraph extends Component {
  render() {
    let times = this.props.times;

    let chartData = {
      labels: times.map(function(lap) {
        return 'Lap ' + lap.nr;
      }),
      datasets: [
        {
          borderColor: 'red',
          pointBackgroundColor: 'rgba(220,220,220,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHitRadius: 16,
          data: times.map(function(lap) {
            return lap.lap;
          }),
        },
      ],
    };

    let chartOptions = {
      bezierCurve: false,

      elements: {
        line: {
          tension: 0,
        },
      },

      legend: {
        display: false,
      },

      tooltips: {
        displayColors: false,
      },

      scales: {
        xAxes: [
          {
            gridLines: {
              color: '#131C2B',
            },
            ticks: {
              fontColor: 'rgba(255,255,255,1)',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: '#131c2b',
            },
            ticks: {
              fontColor: 'rgba(255,255,255,1)',
            },
          },
        ],
      },

      layout: {
        padding: {
          left: 8,
          top: 16,
          right: 8,
          bottom: 8,
        },
      },

      animation: {
        duration: 0,
      },
    };

    return (
      <TimingGraphStyle>
        <Line
          data={chartData}
          options={chartOptions}
          width="600"
          height="250"
        />
      </TimingGraphStyle>
    );
  }
}
