import React, { Component } from 'react';
import '../App.css';
import VenueListItem from './VenueListItem';

import { Link } from 'react-router-dom';
import firebase from '../firebase';

class VenueList extends Component {
  constructor(props) {
    super(props);

    let self = this;

    this.state = {
      venues: [],
    };

    let venues = firebase
      .database()
      .ref('venues')
      .orderByChild('priority');

    venues.once('value').then(function(snapshot) {
      let venues = snapshot.val();

      let allVenues = [];

      Object.keys(venues).forEach(function(key) {
        let item = venues[key];
        allVenues.push({
          key: key,
          name: item.name,
          location: item.location,
          banner: item.banner,
          country: item.country,
        });
      });

      self.setState({
        venues: allVenues,
      });
    });
  }

  render() {
    let renderVenue = function(venue) {
      if (venue) {
        return (
          <Link to={{ pathname: '/venues/' + venue.key }}>
            <VenueListItem {...venue} />
          </Link>
        );
      } else {
        return <div>.</div>;
      }
    };

    // let featuredVenues = [];

    return (
      <div class="container">
        {/* <table width="100%">
          <tr>
            <td>{renderVenue(this.state.venues[0])}</td>
            <td>{renderVenue(this.state.venues[1])}</td>
          </tr>
        </table> */}
        {this.state.venues.map(renderVenue)}
      </div>
    );
  }
}

export default VenueList;
